<template>
  <div class="full-height">
    <router-link :to="{ name: 'Home', params: { id: $route.params.id } }" class="close-button">
      <div class="swi close" style="transform: scale(1.5)" />
    </router-link>
    <section class="bg-F2F4FA flex-1" v-if="investmentProfile">
      <div class="container py-4">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-12">
            <div class="row">
              <div class="yip-title d-none d-lg-block">
                Your Investment profile
              </div>
              <div class="yip-title d-none d-md-block d-lg-none">
                Let’s start create your
                <br />investment profile.
              </div>
            </div>

            <div v-for="q in investmentProfile.filledQuestions" :key="'q-' + q">
              <template v-if="q == 1">
                <div id="q1" class="ipQ">
                  <div class="my-2 my-md-5 py-2">
                    <div class="row">
                      <span class="ipp-page-small" :id="'scroll-' + q">Question 01 / 12</span>
                      <span class="ipp-page-title mt-3">Why are you investing?</span>
                    </div>

                    <div class="squares-container d-flex row g-2 g-lg-4 mt-3">
                      <square title="To buy something big (a car, a house, a world trip, ...)"
                        title-alt="To buy something big" icon="house" :active="investmentProfile.whyAreYouInvesting.indexOf(
                          'buyBig'
                        ) !== -1
                          " @click="
    loggerActions('Click: To buy something big')
  addToArray('whyAreYouInvesting', 'buyBig');
  setStep(2);
  " />
                      <square title="For retirement" icon="accessibility" :active="investmentProfile.whyAreYouInvesting.indexOf(
                        'retirement'
                      ) !== -1
                        " @click="
    loggerActions('Click: For retirement');
  addToArray('whyAreYouInvesting', 'retirement');
  if (investmentProfile.whyAreYouInvesting.indexOf('buyBig') !== -1) {
    setStep(2);
  } else {
    setStep(3);
  }
  " />
                      <square title="For my kids" icon="child" :active="investmentProfile.whyAreYouInvesting.indexOf(
                        'kids'
                      ) !== -1
                        " @click="
    loggerActions('Click: For my kids')
  addToArray('whyAreYouInvesting', 'kids');
  if (investmentProfile.whyAreYouInvesting.indexOf('buyBig') !== -1) {
    setStep(2);
  } else {
    setStep(3);
  }
  " />
                      <square title="Other or just to invest" icon="arrow-to-top" :active="investmentProfile.whyAreYouInvesting.indexOf(
                        'other'
                      ) !== -1
                        " @click="
    loggerActions('Click: Other or just to invest')
  addToArray('whyAreYouInvesting', 'other');
  if (investmentProfile.whyAreYouInvesting.indexOf('buyBig') !== -1) {
    setStep(2);
  } else {
    setStep(3);
  }
  " />
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="q == 2">
                <div id="q1a" class="ipQ">
                  <div class="my-2 my-md-5 py-2" v-if="investmentProfile.whyAreYouInvesting.includes('buyBig')
                    ">
                    <div class="row mt-5">
                      <span class="ipp-page-small" :id="'scroll-' + q">Question 01-a / 12</span>
                      <span class="ipp-page-title mt-3">How you want to spend additional income?</span>
                    </div>

                    <div class="squares-container d-flex row g-2 g-lg-4 mt-3">
                      <square title="I need new car" icon="car" :active="investmentProfile.howYouWantToSpendAdditionalIncome.indexOf(
                        'car'
                      ) !== -1
                        " @click="
    loggerActions('Click: I need new car');
  addToArrayAddIncome('car');
  setStep(3);
  " />
                      <square title="I want a house" icon="house" :active="investmentProfile.howYouWantToSpendAdditionalIncome.indexOf(
                        'house'
                      ) !== -1
                        " @click="
    loggerActions('Click: I want a house')
  addToArrayAddIncome('house');
  setStep(3);
  " />
                      <square title="For education" icon="education" :active="investmentProfile.howYouWantToSpendAdditionalIncome.indexOf(
                        'education'
                      ) !== -1
                        " @click="
    loggerActions('Click: For education');
  addToArrayAddIncome('education');
  setStep(3);
  " />
                      <square title="For a word trip" icon="planet" :active="investmentProfile.howYouWantToSpendAdditionalIncome.indexOf(
                        'travel'
                      ) !== -1
                        " @click="
    loggerActions('Click: For a word trip');
  addToArrayAddIncome('travel');
  setStep(3);
  " />
                      <square title="For a wedding" icon="heart" :active="investmentProfile.howYouWantToSpendAdditionalIncome.indexOf(
                        'wedding'
                      ) !== -1
                        " @click="
    loggerActions('Click: For a wedding')
  addToArray(
    'howYouWantToSpendAdditionalIncome',
    'wedding'
  );
  setStep(3);
  " />
                      <square title="For something good" icon="like" :active="investmentProfile.howYouWantToSpendAdditionalIncome.indexOf(
                        'other'
                      ) !== -1
                        " @click="
    loggerActions('Click: For something good')
  addToArrayAddIncome('other');
  setStep(3);
  " />
                    </div>
                  </div>
                </div>
              </template>

              <div class="my-2 my-md-5 py-2 ipQ" id="q2" v-if="q == 3">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 02 / 12</span>
                  <span class="ipp-page-title mt-3">{{ thirdTitle }}</span>
                  <span class="cl-828282">
                    We know we’re being nosy, but these are some of the most
                    important details in building your investment plan.
                  </span>
                </div>

                <div class="row mt-3">
                  <div class="sw-radio col-md-3 col-12 my-3 mb-md-0">
                    <input type="radio" id="1-radio" name="investmentHorizon-radio"
                      v-model="investmentProfile.investmentHorizon" value="0to3"
                      @change="loggerActions('Change: investmentHorizon-radio:In 0-3 years'); setStep(4)" />
                    <label for="1-radio">In 0-3 years</label>
                  </div>
                  <div class="sw-radio col-md-3 col-12 my-3 mb-md-0">
                    <input type="radio" id="2-radio" name="investmentHorizon-radio"
                      v-model="investmentProfile.investmentHorizon" value="3to5"
                      @change="loggerActions('Change: investmentHorizon-radio: In 3-5 years'); setStep(4)" />
                    <label for="2-radio">In 3-5 years</label>
                  </div>
                  <div class="sw-radio col-md-3 col-12 my-3 mb-md-0">
                    <input type="radio" id="3-radio" name="investmentHorizon-radio"
                      v-model="investmentProfile.investmentHorizon" value="5to10"
                      @change="loggerActions('Change: investmentHorizon-radio: In 5-10 years'); setStep(4)" />
                    <label for="3-radio">In 5-10 years</label>
                  </div>
                  <div class="sw-radio col-md-3 col-12 my-3 mb-md-0">
                    <input type="radio" id="4-radio" name="exampinvestmentHorizonle-radio"
                      v-model="investmentProfile.investmentHorizon" value="above10"
                      @change="loggerActions('Change: investmentHorizon-radio: In over 10 years'); setStep(4)" />
                    <label for="4-radio">In over 10 years</label>
                  </div>
                </div>
              </div>

              <div class="my-2 my-md-5 py-2 ipQ" id="q3" v-if="q == 4">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 03 / 12</span>
                  <span class="ipp-page-title mt-3">How old are you? And, do you have dependents?</span>
                  <span class="cl-828282">
                    We know we’re being nosy, but these are some of the most
                    important details in building your investment plan.
                  </span>
                </div>
                <div class="row mt-3">
                  <div class="col-12 d-flex flex-wrap">
                    <div class="col-12 col-md-6 mb-5 mb-md-0 ps-md-0 pe-md-4">
                      <div class="col-12 d-flex align-items-center">
                        <div class="col-6 cl-212529">Your Age</div>
                        <div class="col-6 d-flex justify-content-end">
                          <div class="sw-number sw-number_right">
                            <input type="number" id="ipYourAge" :value="investmentProfile.age" @change="changeAge"
                              @blur="changeAge" @focus="loggerActions('Focus: Your Age change')" />
                            <div class="sw-number_buttons">
                              <div class="sw-number_up" @click="
                                increment('age', 70);
                              setStep(5);
                              " />
                              <div class="sw-number_down" @click="
                                decrement('age', 18);
                              setStep(5);
                              " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="sw-range mt-3">
                          <Slider v-model="investmentProfile.age" id="ipYourAgeSlider" :min="18" :max="70"
                            :tooltips="false" @change="loggerActions('Change: Your Age change');" />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 pe-md-0 ps-md-4">
                      <div class="col-12 d-flex align-items-center">
                        <div class="col-6 cl-212529">Dependents</div>
                        <div class="col-6 d-flex justify-content-end">
                          <div class="sw-number sw-number_right">
                            <input type="number" id="ipDependents" v-model="investmentProfile.dependents"
                              @change="setStep(5)" @focus="loggerActions('Focus: Your Age change')" />
                            <div class="sw-number_buttons">
                              <div class="sw-number_up" @click="
                                increment('dependents');
                              setStep(5);
                              " />
                              <div class="sw-number_down" @click="
                                decrement('dependents');
                              setStep(5);
                              " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="sw-range mt-3">
                          <Slider v-model="investmentProfile.dependents" id="ipDependentsSlider" :min="0" :max="10"
                            :tooltips="false" @change="loggerActions('Change: Your Age change'); setStep(5)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="my-2 my-md-5 py-2 ipQ" id="q4" v-if="q == 5">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 04 / 12</span>
                  <span class="ipp-page-title mt-3">How do you spend your days and how you earn your
                    money?</span>
                </div>

                <div class="squares-container d-flex row g-2 g-lg-4 mt-3">
                  <square title="Employed" icon="people" :active="investmentProfile.howDoYouSpendYourDays == 'employed'
                    " @click="
    loggerActions('Click: Employed')
  investmentProfile.howDoYouSpendYourDays = 'employed';
  setStep(6);
  " />
                  <square title="Self-employed" icon="user" :active="investmentProfile.howDoYouSpendYourDays == 'self-employed'
                    " @click="
    loggerActions('Click: Self-employed')
  investmentProfile.howDoYouSpendYourDays = 'self-employed';
  setStep(6);
  " />
                  <square title="Retired" icon="accessibility" :active="investmentProfile.howDoYouSpendYourDays == 'retired'
                    " @click="
    loggerActions('Click: Retired')
  investmentProfile.howDoYouSpendYourDays = 'retired';
  setStep(6);
  " />
                  <square title="Studying" icon="education" :active="investmentProfile.howDoYouSpendYourDays == 'studying'
                    " @click="
    loggerActions('Click: Studying')
  investmentProfile.howDoYouSpendYourDays = 'studying';
  setStep(6);
  " />
                  <square title="At home trader" icon="user-in-house"
                    :active="investmentProfile.howDoYouSpendYourDays == 'home'" @click="
                      loggerActions('Click: At home trader')
                    investmentProfile.howDoYouSpendYourDays = 'home';
                    setStep(6);
                    " />
                  <square title="Unemployed" icon="search" :active="investmentProfile.howDoYouSpendYourDays == 'unemployed'
                    " @click="
    loggerActions('Click: Unemployed')
  investmentProfile.howDoYouSpendYourDays = 'unemployed';
  setStep(6);
  " />
                </div>
              </div>

              <div class="my-2 my-md-5 py-2 ipQ" id="q5" v-if="q == 6">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 05 / 12</span>
                  <span class="ipp-page-title mt-3">How much will you make this year?</span>
                  <span class="cl-828282">
                    This is the second most important piece of information we
                    need for your profile. But no worries, an estimate is fine.
                  </span>
                </div>

                <div class="row mt-3">
                  <div class="col-12 d-flex">
                    <div class="col-12">
                      <div class="col-12 d-flex align-items-center">
                        <div class="col-6 cl-212529">Expected income</div>
                        <div class="col-6 d-flex justify-content-end">
                          <div class="sw-number sw-number_right">
                            <input type="text" id="ipExpectedIncome" :value="chfFilter(investmentProfile.expectedIncome)
                              " @change="
    loggerActions('Change: Expected income');
  investmentProfile.expectedIncome = $event.target.value.replace(
    /\D+/g,
    ''
  );
  investmentProfile.monthlySavings =
    +investmentProfile.expectedIncome / 12 *
    0.1;
  setStep(7);
  " @focus="loggerActions('Focus: Expected income')" />
                            <div class="sw-number_buttons">
                              <div class="sw-number_up" @click="

                                increment('expectedIncome', 0, 500);
                              setStep(7);
                              " />
                              <div class="sw-number_down" @click="

                                decrement('expectedIncome', 0, 500);
                              setStep(7);
                              " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="sw-range mt-3">
                          <Slider v-model="investmentProfile.expectedIncome" id="ipExpectedIncomeSlider" :min="50000"
                            :max="1000000" :tooltips="false"
                            @change="loggerActions('Change: Expected income'); setStep(7)" :step="100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="my-2 my-md-5 py-2 ipQ" id="q6" v-if="q == 7">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 06 / 12</span>
                  <span class="ipp-page-title mt-3">
                    During a normal month with no surprising expenses, how much
                    of your monthly income can be saved?
                  </span>
                  <span class="cl-828282">
                    We calculated how much you should invest monthly based on
                    your income. General rule is putting aside 20% monthly puts
                    you in a great position for the future.
                  </span>
                </div>

                <div class="row mt-3">
                  <div class="col-12 d-flex">
                    <div class="col-12">
                      <div class="col-12 d-flex align-items-center">
                        <div class="col-6 cl-212529">Monthly savings</div>
                        <div class="col-6 d-flex justify-content-end">
                          <div class="sw-number sw-number_right">
                            <input type="text" id="ipMonthlySavings" :value="chfFilter(investmentProfile.monthlySavings)
                              " @change="
    loggerActions('Change: Monthly savings');
  investmentProfile.monthlySavings = $event.target.value.replace(
    /\D+/g,
    ''
  );
  setStep(8);
  " @focus="loggerActions('Focus: Monthly savings');" />
                            <div class="sw-number_buttons">
                              <div class="sw-number_up" @click="
                                increment('monthlySavings', 0, 500);
                              setStep(8);
                              " />
                              <div class="sw-number_down" @click="
                                decrement('monthlySavings', 0, 500);
                              setStep(8);
                              " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="sw-range mt-3">
                          <Slider v-model="investmentProfile.monthlySavings" id="ipMonthlySavingsSlider" :min="250"
                            :max="monthlySavingsMax" :tooltips="false"
                            @change="loggerActions('Change: Monthly savings'); setStep(8)" :step="50" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="my-2 my-md-5 py-2 ipQ" id="q7" v-if="q == 8">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 07 / 12</span>
                  <span class="ipp-page-title mt-3">Time for some easy math</span>
                  <span class="cl-828282">
                    Add up the value of your home (what it would sell for today,
                    ignore your mortgage), your car (ignore your loan) and any
                    other valuables you own. Now tell us the value of your
                    assets. An estimate is ok.
                  </span>
                </div>

                <div class="row mt-3">
                  <div class="col-12 d-flex">
                    <div class="col-12">
                      <div class="col-12 d-flex align-items-center">
                        <div class="col-6 cl-212529">Assets</div>
                        <div class="col-6 d-flex justify-content-end">
                          <div class="sw-number sw-number_right">
                            <input type="text" id="ipAssets" :value="chfFilter(investmentProfile.assets)" @change="
                              loggerActions('Change: Assets');
                            investmentProfile.assets = $event.target.value.replace(
                              /\D+/g,
                              ''
                            );
                            setStep(9);
                            " @focus="loggerActions('Focus: Assets');" />
                            <div class="sw-number_buttons">
                              <div class="sw-number_up" @click="
                                increment('assets', 0, 500);
                              setStep(9);
                              " />
                              <div class="sw-number_down" @click="
                                decrement('assets', 0, 500);
                              setStep(9);
                              " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="sw-range mt-3">
                          <Slider v-model="investmentProfile.assets" id="ipAssetsSlider" :min="10000" :max="5000000"
                            :tooltips="false" @change="loggerActions('Change: Assets'); setStep(9)" :step="100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="my-2 my-md-5 py-2 ipQ" id="q8" v-if="q == 9">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 08 / 12</span>
                  <span class="ipp-page-title mt-3">Do you currently have savings or investments?</span>
                  <span class="cl-828282">
                    Add up your savings, investment portfolios and the money you
                    keep under your mattress, but don’t include your home or
                    car. Usually thay shuild be smaller than your assets. An
                    estimate is ok too.
                  </span>
                </div>

                <div class="row mt-3">
                  <div class="col-12 d-flex">
                    <div class="col-12">
                      <div class="col-12 d-flex align-items-center">
                        <div class="col-6 cl-212529">Savings</div>
                        <div class="col-6 d-flex justify-content-end">
                          <div class="sw-number sw-number_right">
                            <input type="text" id="ipSavings" :value="chfFilter(investmentProfile.savings)" @change="
                              loggerActions('Change: Savings');
                            investmentProfile.savings = $event.target.value.replace(
                              /\D+/g,
                              ''
                            );
                            setStep(10);
                            " @focus="loggerActions('Focus: Savings');" />
                            <div class="sw-number_buttons">
                              <div class="sw-number_up" @click="
                                increment('savings', 0, 500);
                              setStep(10);
                              " />
                              <div class="sw-number_down" @click="
                                decrement('savings', 0, 500);
                              setStep(10);
                              " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="sw-range mt-3">
                          <Slider v-model="investmentProfile.savings" id="ipSavingsSlider" :min="10000" :max="5000000"
                            :tooltips="false" @change="loggerActions('Change: Savings'); setStep(10)" :step="100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="my-2 my-md-5 py-2 ipQ" id="q9" v-if="q == 10">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 09 / 12</span>
                  <span class="ipp-page-title mt-3">You're almost there! What about your debts?</span>
                  <span class="cl-828282">
                    Add up any mortgages (what's left of it, if you have one),
                    loans, leases or long-term credit card debt. An estimate is
                    pretty fine here.
                  </span>
                </div>

                <div class="row mt-3">
                  <div class="col-12 d-flex">
                    <div class="col-12">
                      <div class="col-12 d-flex align-items-center">
                        <div class="col-6 cl-212529">Debts</div>
                        <div class="col-6 d-flex justify-content-end">
                          <div class="sw-number sw-number_right">
                            <input type="text" id="ipDebts" :value="chfFilter(investmentProfile.debts)" @change="
                              loggerActions('Change: Debts');
                            investmentProfile.debts = $event.target.value.replace(
                              /\D+/g,
                              ''
                            );
                            setStep(11);
                            " @focus="loggerActions('Focus: Debts');" />
                            <div class="sw-number_buttons">
                              <div class="sw-number_up" @click="
                                increment('debts', 0, 500);
                              setStep(11);
                              " />
                              <div class="sw-number_down" @click="
                                decrement('debts', 0, 500);
                              setStep(11);
                              " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="sw-range mt-3">
                          <Slider v-model="investmentProfile.debts" id="ipDebtsSlider" :min="0" :max="1000000"
                            :tooltips="false" @change="loggerActions('Change: Debts'); setStep(11)" :step="100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="my-2 my-md-5 py-2 ipQ" id="q10" v-if="q == 11">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 10 / 12</span>
                  <span class="ipp-page-title mt-3">Have you ever invested money before?</span>
                </div>
                <div class="squares-container d-flex row g-2 g-lg-4 mt-3">
                  <square title="It's my first time investing" :active="investmentProfile.investedBefore == false" @click="
                    loggerActions('Click: It`s my first time investing');
                  investmentProfile.investedBefore = false;
                  setStep(12);
                  " />
                  <square title="Yes I have invested before" :active="investmentProfile.investedBefore == true" @click="
                    loggerActions('Click: Yes I have invested before');
                  investmentProfile.investedBefore = true;
                  setStep(12);
                  " />
                </div>
              </div>

              <div class="my-2 my-md-5 py-2 ipQ" id="q11" v-if="q == 12">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 11 / 12</span>
                  <span class="ipp-page-title mt-3">
                    If you were to invest CHF 172,500, which scenario would you
                    be happiest with?
                  </span>
                  <span class="cl-828282">
                    Investing comes with risk. There’s no right or wrong, it’s
                    about what you feel comfortable with.
                  </span>
                </div>

                <div class="row mt-3">
                  <div class="bordered-wrapper py-2">
                    <div class="sw-radio col-12 my-3 mb-md-0">
                      <input type="radio" id="5-radio" name="riskAppetite1-radio"
                        v-model="investmentProfile.riskAppetite1" value="slow"
                        @change="loggerActions('Change: riskAppetite1-radio'); setStep(13)" />
                      <label for="5-radio">
                        I take some losses (CHF 34,500) to earn more long term
                        (CHF 60,375)
                      </label>
                    </div>
                  </div>
                  <div class="bordered-wrapper py-2">
                    <div class="sw-radio col-12 my-3 mb-md-0">
                      <input type="radio" id="6-radio" name="riskAppetite1-radio"
                        v-model="investmentProfile.riskAppetite1" value="balanced"
                        @change="loggerActions('Change: riskAppetite1-radio'); setStep(13)" />
                      <label for="6-radio">
                        I'm willing to take small losses (CHF 17,250) to earn a
                        little long term (CHF 25,875)
                      </label>
                    </div>
                  </div>
                  <div class="bordered-wrapper py-2">
                    <div class="sw-radio col-12 my-3 mb-md-0">
                      <input type="radio" id="7-radio" name="riskAppetite1-radio"
                        v-model="investmentProfile.riskAppetite1" value="highrisk"
                        @change="loggerActions('Change: riskAppetite1-radio'); setStep(13)" />
                      <label for="7-radio">
                        I'm comfortable with losses (CHF 51,750) to maximize
                        what I earn long term (CHF 103,500)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-2 my-md-5 py-2 ipQ" id="q12" v-if="q == 13">
                <div class="row mt-5">
                  <span class="ipp-page-small" :id="'scroll-' + q">Question 12 / 12</span>
                  <span class="ipp-page-title mt-3">
                    If your CHF 172'500 investments lose CHF 51'750 and you only
                    have CHF 120'750 left, what do you do?
                  </span>
                  <span class="cl-828282">
                    Again, there’s no right or wrong answer, it’s about what
                    your good feeling
                  </span>
                </div>
                <div class="squares-container d-flex row g-2 g-lg-4 mt-3">
                  <square title="Buy more" icon="arrow-tr2bl" :active="investmentProfile.riskAppetite2 == 'buy'" @click="
                    loggerActions('Click: Buy more');
                  investmentProfile.riskAppetite2 = 'buy';
                  setStep(14);
                  " />
                  <square title="Stay as–is" icon="bookmark" :active="investmentProfile.riskAppetite2 == 'stay'" @click="
                    loggerActions('Click: Stay as–is');
                  investmentProfile.riskAppetite2 = 'stay';
                  setStep(14);
                  " />
                  <square title="Sell everything" icon="arrow-bl2tr" :active="investmentProfile.riskAppetite2 == 'sell'"
                    @click="
                      loggerActions('Click: Sell everything');
                    investmentProfile.riskAppetite2 = 'sell';
                    setStep(14);
                    " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-090D2E" v-if="investmentProfile" id="bottomScroll">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-12">
            <div class="col-12 my-5">
              <div class="btn btn-info-custom col-12 col-md-auto button-padding" id="ipContinue" @click="nextStep" v-if="validToNext.includes(investmentProfile.filledQuestions) &&
                investmentProfile.filledQuestions !== 14
                ">
                Continue&nbsp;&nbsp; &rarr;
              </div>
              <router-link v-if="investmentProfile.filledQuestions == 14"
                @click="loggerActions('Change pages: YourInvestmentProfile')" :to="{
                  name: 'YourInvestmentProfile',
                  params: { id: $route.params.id },
                }" class="btn btn-info-custom col-12 col-md-auto button-padding" id="ipGetForecast">Get your investment
                forecast!&nbsp;&nbsp; &rarr;</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InvProfileSquare from "@/components/InvProfileSquare.vue";
import Slider from "@vueform/slider";
import { mapActions, mapGetters } from "vuex";
import { prepareWatchers } from "@/services/prepareWatchers";
import axios from "axios";


var watchers = prepareWatchers("investmentProfile");

export default {
  components: {
    square: InvProfileSquare,
    Slider,
  },
  name: "InvestmentProfile",

  data: () => {
    return {
      investmentProfile: null,
      validToNext: [4, 6, 7, 8, 9, 10],
    };
  },
  watch: {
    ...watchers,
    investmentProfile: {
      handler(newValue) {
        if (newValue.expectedIncome !== this.investmentProfile.expectedIncome) {
          newValue.monthlySavings = +newValue.expectedIncome / 12 * 0.1
            ;
        }
        this.setValue({
          key: "investmentProfile",
          value: newValue,
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["onbForm"]),
    monthlySavingsMax() {
      if (!this.investmentProfile || !this.investmentProfile.expectedIncome) {
        return 5000;
      }
      return (
        Math.ceil(this.investmentProfile.expectedIncome / 12 / 4 / 1000) * 1000
      );
    },
    buttonTitle() {
      if (!this.investmentProfile) return "";
      if (this.investmentProfile.filledQuestions < 13) {
        return "Continue";
      } else {
        return "Get your investment forecast!";
      }
    },
    thirdTitle() {
      // buyBigAnswers, investAnswers, context
      const buyBigAnswers = this.investmentProfile
        .howYouWantToSpendAdditionalIncome;
      const investAnswers = this.investmentProfile.whyAreYouInvesting;

      const baseWhen = "When do you think you will need the money for your ";
      const studyWhen =
        "Good for you! You’re investing in yourself too. When will you need the money for school?";
      const whenOthers =
        "When do you think you will need the money for this mystery purchase? (You can tell us all about it later)";
      const whenRet = "When do you plan to retire?";
      const whenKid =
        "When do you think you will need the money back from your Investment Account for your children?";
      if (buyBigAnswers && investAnswers.indexOf("buyBig") > -1) {
        if (buyBigAnswers.length === 1) {
          if (buyBigAnswers.indexOf("education") !== -1) {
            return studyWhen.trim();
          }
          if (buyBigAnswers.indexOf("other") !== -1) {
            return whenOthers.trim();
          }
          return `${baseWhen} ${buyBigAnswers[0]}?`.trim();
        }
        return buyBigAnswers[0]
          ? `${baseWhen} ${buyBigAnswers[0]} and others?`.trim()
          : "When do you think you will need the money back from your Investment Account?".trim();
      }
      if (investAnswers.indexOf("retirement") > -1) {
        return whenRet.trim();
      }
      if (investAnswers.indexOf("kids") > -1) {
        return whenKid.trim();
      }
      return whenOthers.trim();
    },
    filteredValues() {
      return {
        monthlySavings: this.investmentProfile.monthlySavings,
      };
    },
  },
  methods: {
    ...mapActions(["setValue"]),

    async loggerActions(action) {
      const resIp = await axios('https://api64.ipify.org?format=json')

      await axios.post("https://dashboard.simplewealth.ch/api/users/user/history", {
        ip: resIp.data.ip,
        userId: "41",
        action,
        msg: `page ${location.pathname} opened`,
        keyAction: action.includes("pages:"),
        email: "rulez@gmail.com"
      });
    },
    changeAge(e) {
      var age = e.target.value.replace(/\D/g, "");
      if (age < 18) {
        age = 18;
      }
      if (age > 70) {
        age = 70;
      }
      this.investmentProfile.age = age;
    },
    addToArrayAddIncome(key) {
      var item = this.investmentProfile.howYouWantToSpendAdditionalIncome;
      var refList = ["car", "house", "education", "travel", "wedding", "other"];
      var result = [];

      if (item.includes(key)) {
        item = item.filter((el) => {
          return el != key;
        });
      } else {
        for (var i in refList) {
          if (refList[i] == key || item.includes(refList[i])) {
            result.push(refList[i]);
          }
        }
        item = result;
      }

      this.investmentProfile.howYouWantToSpendAdditionalIncome = item;
    },
    addToArray(key, value) {
      if (this.investmentProfile[key].indexOf(value) === -1) {
        this.investmentProfile[key].push(value);
      } else {
        this.investmentProfile[key] = this.investmentProfile[key].filter(
          function (v) {
            return v != value;
          }
        );
      }
    },
    increment(key, max = 0, step = 1) {
      const num = this.investmentProfile[key];
      if (max > 0) {
        if (num + step <= max) {
          this.investmentProfile[key] = num + step;
        }
      } else {
        this.investmentProfile[key] = num + step;
      }
    },
    decrement(key, min = 0, step = 1) {
      const num = this.investmentProfile[key];
      if (num - step >= min) {
        this.investmentProfile[key] = num - step;
      }
    },
    nextStep() {
      console.log('suka')
      console.log(this.investmentProfile);
      if (this.investmentProfile.filledQuestions === 13) {
        console.log("go to route there");
      } else {
        if (!this.validate()) {
          return false;
        }

        if (this.investmentProfile.filledQuestions < 13) {
          this.investmentProfile.filledQuestions++;
          console.log(this.investmentProfile);
          this.scrollToQuestion(this.investmentProfile.filledQuestions);
        }
      }
    },
    setStep(step) {
      if (this.investmentProfile.filledQuestions <= step) {
        this.investmentProfile.filledQuestions = step;
        this.scrollToQuestion(this.investmentProfile.filledQuestions);
      }
    },

    validate() {
      const q = this.investmentProfile.filledQuestions;
      if (this.validToNext.includes(q)) return true;
      console.log("Not valid form");
      return false;
    },
    scrollToQuestion(q) {
      setTimeout(() => {
        document.getElementById('scroll-' + q).scrollIntoView({
          behavior: "smooth",
        });
        // window.scrollTo({
        //   top: document.body.scrollHeight,
        //   behavior: "smooth",
        // });
      }, 100);
    },
    chfFilter(value) {
      return "CHF " + new Intl.NumberFormat("en-US").format(value);
    },
  },
  mounted() {
    this.investmentProfile = this.onbForm.investmentProfile;
  },
};
</script>
