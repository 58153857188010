<template>
  <div class="col-12 col-md-3 square">
    <div
      class="ratio ratio-1x1 questions-square d-none d-md-block"
      :class="{ active: active }"
    >
      <div class="p-4">
        <div>{{ title }}</div>
        <div
          class="qs-icon m-3 swi"
          :class="[icon, active ? 'swi_w' : '']"
          v-if="icon.length"
        />
      </div>
    </div>

    <div
      class="questions-square d-md-none my-2"
      :class="{ active: active }"
    >
      <div class="p-3 d-flex justify-content-between align-items-center">
        <div>{{ titleAlt.length > 0 ? titleAlt : title }}</div>
        <div
          class="swi"
          :class="[icon, active ? 'swi_w' : '']"
          v-if="icon.length"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvProfileSquare",
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    titleAlt: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>