import { isObject, isArray } from "lodash"
import store from "@/store";

const watcherFunc = (key, nv, ov) => {
    if (ov) {
        if (nv !== ov && !store.state.formChangedData.includes(key)) {
            store.state.formChangedData.push(key);
        }
    }
}

function prepareArray(arr, key, stringKey) {
    var data = {};
   
    arr.forEach((item, i) => {
        
        Object.keys(item).forEach(objItem => {
            data[stringKey + "." + key + "." + i + "." + objItem] = function (newValue, oldValue) { watcherFunc(stringKey + "." + key + "." + i + "." + objItem, newValue, oldValue) };
        })
    });
    return data;
}

export const prepareWatchers = (key = null, form = null, addKey = null) => {
    var watchers = {}
    let objForm = form ?? store.state.form
    let procForm = key ? objForm[key] : objForm;
    let stringKey = key ?? "form";
    Object.keys(procForm).forEach((formKey) => {
        if (isObject(procForm[formKey]) && !isArray(procForm[formKey])) {
            watchers = {
                ...prepareWatchers(formKey, procForm, addKey ?? stringKey + "."),
                ...watchers
            }
        } else if (isObject(procForm[formKey]) && isArray(procForm[formKey])) {

            watchers = {
                ...prepareArray(procForm[formKey], formKey, stringKey),
                ...watchers
            }
        } else {
            if (addKey) {
                watchers[addKey + stringKey + "." + formKey] = function (newValue, oldValue) { watcherFunc(addKey + stringKey + "." + formKey, newValue, oldValue) };
            } else {
                watchers[stringKey + "." + formKey] = function (newValue, oldValue) { watcherFunc(stringKey + "." + formKey, newValue, oldValue) };
            }
        }
    })
    return watchers;
};